export const currency = amt =>
  amt?.toLocaleString("en-US", { style: "currency", currency: "USD" });

export const convertToCents = str => {
  if (!str) {
    return 0;
  }

  const number = Number(str).toFixed(2);
  return Number(number) * 100;
};

import { put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  ADD_ITEM,
  DELETE_ITEM,
  UPDATE_ITEM,
  ADD_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
} from "redux/actionTypes";
import { fetchProducts } from "redux/actions";

export function* handleAddItem(action) {
  try {
    const data = new FormData();
    const { merchantId, item } = action.payload;
    const {
      description,
      name,
      price,
      image,
      unit,
      category,
      is_seasonal,
    } = item;
    data.append(
      "product",
      JSON.stringify({ description, name, price, category, unit, is_seasonal }),
    );
    if (image) {
      data.append("img", image.data);
    }
    yield axios.post(
      `${process.env.REACT_APP_API_URL}/merchant/${merchantId}/products`,
      data,
    );
    yield put(fetchProducts({ merchantId }));
  } catch (e) {
    console.log(e);
  }
}

export function* handleDeleteItem(action) {
  try {
    const { merchantId, product_id } = action.payload;
    yield axios.delete(
      `${process.env.REACT_APP_API_URL}/merchant/${merchantId}/products/${product_id}`,
    );
    yield put(fetchProducts({ merchantId }));
  } catch (e) {
    console.log(e);
  }
}

export function* handleUpdateItem(action) {
  try {
    const data = new FormData();
    const { merchantId, item } = action.payload;
    const {
      product_id,
      description,
      name,
      price,
      image,
      unit,
      category,
      is_seasonal,
    } = item;
    data.append(
      "product",
      JSON.stringify({
        description,
        name,
        price,
        image,
        unit,
        category,
        is_seasonal,
      }),
    );
    if (image && image.data) {
      data.append("img", image.data);
    }
    yield axios.put(
      `${process.env.REACT_APP_API_URL}/merchant/${merchantId}/products/${product_id}`,
      data,
    );
    yield put(fetchProducts({ merchantId }));
  } catch (e) {
    console.log(e);
  }
}
export function* handleAddCategory(action) {
  try {
    const { merchantId, category } = action.payload;
    yield axios.post(
      `${process.env.REACT_APP_API_URL}/merchant/${merchantId}/categories`,
      { category },
    );
    yield put(fetchProducts({ merchantId }));
  } catch (e) {
    console.log(e);
  }
}

export function* handleDeleteCategory(action) {
  try {
    const { category, merchantId } = action.payload;
    yield axios.delete(
      `${process.env.REACT_APP_API_URL}/merchant/${merchantId}/categories/${category}`,
    );
    yield put(fetchProducts({ merchantId }));
  } catch (e) {
    console.log(e);
  }
}

export function* handleUpdateCategory(action) {
  try {
    const { currentCategory, newCategory, merchantId } = action.payload;
    yield axios.put(
      `${process.env.REACT_APP_API_URL}/merchant/${merchantId}/categories/${currentCategory}`,
      {
        newCategory,
      },
    );
    yield put(fetchProducts({ merchantId }));
  } catch (e) {
    console.log(e);
  }
}

export function* watchItemManagement() {
  yield takeEvery(ADD_ITEM, handleAddItem);
  yield takeEvery(DELETE_ITEM, handleDeleteItem);
  yield takeEvery(UPDATE_ITEM, handleUpdateItem);
  yield takeEvery(ADD_CATEGORY, handleAddCategory);
  yield takeEvery(DELETE_CATEGORY, handleDeleteCategory);
  yield takeEvery(UPDATE_CATEGORY, handleUpdateCategory);
}

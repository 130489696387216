import { put, takeEvery, call, select } from "redux-saga/effects";
import axios from "axios";
import {
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_STARTED,
  FETCH_PRODUCTS_FAILED,
  FETCH_PRODUCTS_SUCCEEDED,
  FETCH_MORE,
  FETCH_MORE_STARTED,
  FETCH_MORE_SUCCEEDED,
  FETCH_MORE_FAILED,
} from "../actionTypes";
import Products from "../../assets/products.json";
import Categories from "../../assets/categories.json";

function fetchProducts({ merchantId }) {
  // return axios
  //   .get(`${process.env.REACT_APP_API_URL}/merchant/${merchantId}/products`, {
  //     // params: { limit: 10, offset: 0 },
  //   })
  //   .then(res => res.data);
  return { products: Products, categories: Categories };
}

export function* handleFetchProducts(action) {
  try {
    yield put({ type: FETCH_PRODUCTS_STARTED });
    const { products, categories } = yield call(fetchProducts, action.payload);
    yield put({
      type: FETCH_PRODUCTS_SUCCEEDED,
      payload: {
        products,
        categories: Object.keys(categories),
      },
    });
  } catch (e) {
    yield put({ type: FETCH_PRODUCTS_FAILED });
  }
}

export function* handleFetchMoreProducts(action) {
  try {
    const { merchantId } = action.payload;
    const state = yield select();
    const currentProducts = state.products.products;
    const last = currentProducts[currentProducts.length - 1];

    yield put({ type: FETCH_MORE_STARTED });

    const { data } = yield axios.get(
      `${process.env.REACT_APP_API_URL}/merchant/${merchantId}/products`,
      {
        params: { limit: 10, offset: last.name },
      },
    );

    const { products, categories } = data;
    yield put({
      type: FETCH_MORE_SUCCEEDED,
      payload: { products, categories },
    });
  } catch (e) {
    yield put({ type: FETCH_MORE_FAILED });
  }
}

export function* watchProducts() {
  yield takeEvery(FETCH_PRODUCTS, handleFetchProducts);
  yield takeEvery(FETCH_MORE, handleFetchMoreProducts);
}

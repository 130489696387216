import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/actions";
import styled from "styled-components";
import CategoryTable from "components/Table/CategoryTable";
import ItemRow from "./ItemRow";
import AddItemRow from "../AddItemRow";
import UpArrow from "assets/up-arrow.svg";
import DownArrow from "assets/down-arrow.svg";
import TableHeader from "./TableHeader";
import { forceCheck } from "react-lazyload";

const ItemTable = () => {
  const dispatch = useDispatch();
  const { products, categories } = useSelector(state => state.products);
  const [isAdding, setIsAdding] = useState(false);
  const [editingRowId, setEditingRowId] = useState("");
  const [rowData, setRowData] = useState({
    category: categories[0],
    merchantId: process.env.REACT_APP_MERCHANT_ID,
  });

  const [sideSelection, setSideSelection] = useState("products");
  const [searchItem, setSearchItem] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [sortColumn, setSortColumn] = useState("");

  const handleFileUpload = event => {
    const [file] = event.target.files || [];
    if (file && file.type.startsWith("image") && file.size < 1024 * 1000) {
      setRowData({
        ...rowData,
        image: {
          data: file,
          url: URL.createObjectURL(file),
        },
      });
    }
  };

  const resetRow = () => {
    setRowData({
      name: "",
      description: "",
      price: "0",
      unit: "",
      merchantId: process.env.REACT_APP_MERCHANT_ID,
      file: {},
    });
  };

  const sideNavHandler = selection => {
    setSideSelection(selection);
    setEditingRowId("");
  };

  const addItem = () => {
    dispatch(
      actions.addItem({
        item: { ...rowData, price: Number(rowData.price) },
        merchantId: process.env.REACT_APP_MERCHANT_ID,
      }),
    );
    setIsAdding(false);
    resetRow();
  };

  const cancelRow = () => {
    setIsAdding(false);
    resetRow();
  };

  const addRow = () => {
    setIsAdding(true);
    resetRow();
  };
  const sortProducts = props => {
    if (props.column != sortColumn) {
      products.sort((a, b) => {
        return props.column === "name"
          ? a.name.localeCompare(b.name)
          : props.column === "description"
          ? a.description.localeCompare(b.description)
          : props.column === "price"
          ? a.price - b.price
          : props.column === "units"
          ? !a.unit
            ? -1
            : !b.unit
            ? 1
            : a.unit.localeCompare(b.unit)
          : props.column === "category"
          ? a.category.localeCompare(b.category)
          : a.is_seasonal === true
          ? -1
          : 1;
      });
    } else {
      products.reverse();
    }
  };

  const sortHandler = column => {
    if (column !== sortColumn) {
      console.log("New Column");
      setSortColumn(column);
      setSortOrder("ascending");
    } else {
      setSortOrder(
        sortOrder === "descending" || sortOrder === ""
          ? "ascending"
          : "descending",
      );
    }

    sortProducts({ column: column });
  };

  const SortArrow = props => {
    return (
      props.self === sortColumn && (
        <img
          style={{ height: 15, width: 20 }}
          src={sortOrder === "ascending" ? DownArrow : UpArrow}
        />
      )
    );
  };

  return (
    <TableContainer>
      <SideNav>
        <SideButton
          selection={sideSelection === "products"}
          onClick={() => {
            sideNavHandler("products");
          }}
        >
          PRODUCTS
        </SideButton>
        <SideButton
          selection={sideSelection === "categories"}
          onClick={() => {
            sideNavHandler("categories");
          }}
        >
          CATEGORIES
        </SideButton>
      </SideNav>
      {sideSelection === "products" ? (
        <div className="container mx-auto bg-white shadow-2xl rounded-lg p-10 flex flex-col">
          <div className="flex justify-around mb-10">
            <div className="relative max-w-lg">
              <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
                <svg
                  className="h-5 w-5 text-gray-500"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <input
                className="w-full border rounded-md pl-10 pr-4 py-2 focus:border-blue-500 focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="Search"
                value={searchItem}
                onChange={event => {
                  forceCheck();
                  setSearchItem(event.target.value.toLowerCase());
                }}
              />
            </div>

            <button
              className="bg-primary px-6 py-2 text-white rounded-3xl"
              onClick={addRow}
            >
              + New Product
            </button>
          </div>

          <table>
            <thead>
              <tr>
                <TableHeader label="NAME" />
                <TableHeader label="DESCRIPTION" />
                <TableHeader label="PRICE" />
                <TableHeader label="UNITS" />
                <TableHeader label="CATEGORY" />
                <TableHeader label="IMAGE" />
                <TableHeader label="SEASONAL" />
                <TableHeader label="" />
              </tr>
            </thead>
            <tbody>
              {isAdding && (
                <AddItemRow
                  {...{
                    onAdd: addItem,
                    onCancel: cancelRow,
                    categories,
                    handleFileUpload,
                    rowData,
                    setRowData,
                    setIsAdding,
                  }}
                />
              )}
              {products.map(
                product =>
                  product.name.toLowerCase().includes(searchItem) && (
                    <ItemRow
                      key={product.product_id}
                      isAdding={isAdding}
                      editingRowId={editingRowId}
                      setEditingRowId={setEditingRowId}
                      product={product}
                      categories={categories}
                    />
                  ),
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <CategoryTable />
      )}
    </TableContainer>
  );
};

const TableContainer = styled.section({
  display: "flex",
  flexDirection: "row",
  paddingTop: 70,
});

const SideNav = styled.section({
  display: "flex",
  flexDirection: "column",
  fontFamily: "roboto",
  color: "#245FB8",
  fontSize: "25px",
  flex: 1,
  paddingRight: 15,
});

const SideButton = styled.div(props => ({
  paddingBottom: 20,
  cursor: "pointer",
  opacity: props.selection ? "100%" : "60%",
  color: props.selection ? "inherit" : "black",
  textDecoration: props.selection ? "underline" : "none",
}));

export default ItemTable;

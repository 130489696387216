import styled from "styled-components";

export const RobotoText = styled.div({
  color: "inherit",
  fontFamily: "Roboto",
  fontWeight: 400,
  fontSize: 16,
  letterSpacing: 1.5,
});

export const OswaldText = styled.div({
  color: "inherit",
  fontFamily: "Oswald",
  fontWeight: 400,
  fontSize: 16,
  letterSpacing: 1.5,
});

export const CourierText = styled.div({
  color: "inherit",
  fontFamily: "Courier Prime",
  fontWeight: 400,
  fontSize: 16,
  letterSpacing: 0.15,
});

export const Button = styled.button(props => ({
  backgroundColor: props.disabled
    ? `${props.theme.color.primary}bb`
    : props.theme.color.primary,
  border: "none",
  display: "flex",
  fontSize: 16,
  fontWeight: 700,
  justifyContent: "center",
  alignItems: "center",
  color: "#F0FFF0",
  cursor: props.disabled ? "initial" : "pointer",
  letterSpacing: 1.5,
  height: 50,
  ":hover": !props.disabled ? { backgroundColor: "#1d4b91" } : {},
}));

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Cart from "./components/Cart";
import Header from "./components/Header";
import ConfirmationPage from "./components/ConfirmationPage";
import AuthTable from "components/Table/AuthTable";
import "./App.css";
import "./index.css";
import PrivacyPolicy from "components/support/PrivacyPolicy";
import TermsOfService from "components/support/TermsOfService";
import Contact from "components/support/Contact";
import Home from "components/Home";
import Footer from "./components/Footer";
import Location from "./components/Location";
import Checkout from "./components/Checkout/Checkout";
import { fetchMerchant, fetchProducts } from "redux/actions";

function App() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const openProductModal = product => {
    setOpen(true);
    setSelectedItem(product);
  };

  useEffect(() => {
    dispatch(fetchProducts({ merchantId: process.env.REACT_APP_MERCHANT_ID }));
    // TODO - merchantId will be dynamic later.
    // dispatch(fetchMerchant({ merchantId: process.env.REACT_APP_MERCHANT_ID }));
  }, [dispatch]);

  return (
    <Router>
      <Header />
      <div className="min-h-screen container mx-auto">
        <Switch>
          {/* <Route path="/contact">
            <Contact />
          </Route> */}
          {/* <Route path="/legal/terms">
            <TermsOfService />
          </Route>
          <Route path="/legal/privacy">
            <PrivacyPolicy />
          </Route> */}
          {/* <Route path="/order/confirmation">
            <ConfirmationPage />
          </Route> */}
          {/* <Route path="/table">
            <AuthTable />
          </Route> */}
          {/* <Route path="/checkout">
            <Checkout />
          </Route> */}
          {/* <Route path="/cart">
            <Cart />
          </Route> */}
          <Route path="/location">
            <Location />
          </Route>
          <Route path="/">
            <Home onProductClick={openProductModal} />
          </Route>
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default App;

import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-gray-200 mt-10">
      <div className="container mx-auto px-6 py-3 flex justify-between items-center">
        {/* <Link className="py-2 text-gray-500 sm:py-0" to="/legal/privacy">
          Privacy Policy
        </Link>
        <Link className="py-2 text-gray-500 sm:py-0" to="/legal/terms">
          Terms of Use
        </Link> */}
      </div>
    </footer>
  );
};

export default Footer;

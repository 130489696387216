import { all } from "redux-saga/effects";
import { watchProducts } from "./products";
import { watchStartCheckout } from "./startCheckout";
import { watchItemManagement } from "./itemManagement";
import { watchMerchant } from "./merchant";

export default function* rootSaga() {
  yield all([
    watchProducts(),
    watchStartCheckout(),
    watchItemManagement(),
    watchMerchant(),
  ]);
}

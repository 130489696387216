import styled from "styled-components";

// TODO - finish
const Contact = () => {
  return (
    <ContactForm>
      <ContactInput />
    </ContactForm>
  );
};

const ContactForm = styled.form({
  marginTop: 40,
});

const ContactInput = styled.input({});

export default Contact;

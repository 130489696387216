import {
  ADD_TO_CART,
  UPDATE_CART,
  CHECKOUT_SUCCEEDED,
  CHECKOUT_FAILED,
} from "../actionTypes";

const initialState = {
  items: [],
  subtotal: 0,
  size: 0,
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_CART: {
      const { item } = action.payload;
      const index = state.items.findIndex(
        element => element.product_id === item.product_id,
      );
      let items = [];
      let { size } = state;
      if (index >= 0) {
        size -= state.items[index].quantity;
        items = state.items.map(cartItem => {
          const updatedItem = cartItem;
          if (cartItem.product_id === item.product_id) {
            updatedItem.quantity += item.quantity;
            size += updatedItem.quantity;
          }
          return updatedItem;
        });
      } else {
        items = [...state.items, item];
        size += item.quantity;
      }
      return {
        ...state,
        items,
        subtotal: state.subtotal + item.price * item.quantity,
        size,
      };
    }
    case UPDATE_CART: {
      const { item, updatedQuantity } = action.payload;
      const index = state.items.findIndex(cartItem => {
        return cartItem.product_id === item.product_id;
      });
      const items = [...state.items];
      let { subtotal, size } = state;
      size -= items[index].quantity;
      subtotal -= items[index].price * items[index].quantity;
      if (parseInt(updatedQuantity) === 0) {
        items.splice(index, 1);
      } else {
        items[index].quantity = parseInt(updatedQuantity);
        subtotal += items[index].price * items[index].quantity;
        size += items[index].quantity;
      }

      return {
        ...state,
        items,
        subtotal,
        size,
      };
    }
    case CHECKOUT_SUCCEEDED: {
      return initialState;
    }
    case CHECKOUT_FAILED: {
      return { ...state, items: action.payload };
    }
    default:
      return state;
  }
}

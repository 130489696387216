import { put, takeEvery, call } from "redux-saga/effects";
import {
  FETCH_MERCHANT,
  FETCH_MERCHANT_SUCCEEDED,
  FETCH_MERCHANT_FAILED,
  ACCESS_TABLE_SUCCESS,
  ACCESS_TABLE_FAILED,
  ACCESS_TABLE,
} from "../actionTypes";

function fetchMerchant({ merchantId }) {
  return fetch(
    `${process.env.REACT_APP_API_URL}/merchant/${merchantId}`,
  ).then(res => res.json());
}

export function* handleFetchMerchant(action) {
  try {
    const { merchant } = yield call(fetchMerchant, action.payload);
    yield put({
      type: FETCH_MERCHANT_SUCCEEDED,
      payload: merchant,
    });
  } catch (e) {
    yield put({ type: FETCH_MERCHANT_FAILED });
  }
}

export function* handleAccessTable(action) {
  try {
    const table = yield fetch(`${process.env.REACT_APP_API_URL}/auth`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ key: action.payload }),
    }).then(res => {
      return res.status;
    });
    if (table === 200) {
      yield put({
        type: ACCESS_TABLE_SUCCESS,
      });
    } else {
      yield put({
        type: ACCESS_TABLE_FAILED,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

export function* watchMerchant() {
  yield takeEvery(FETCH_MERCHANT, handleFetchMerchant);
  yield takeEvery(ACCESS_TABLE, handleAccessTable);
}

import {
  ADD_TO_CART,
  UPDATE_CART,
  FETCH_PRODUCTS,
  START_CHECKOUT,
  GET_ORDER_DETAILS,
  ADD_ITEM,
  DELETE_ITEM,
  ADD_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
  UPDATE_ITEM,
  FETCH_MERCHANT,
  FETCH_MORE,
  ACCESS_TABLE,
} from "./actionTypes";

export const accessTable = key => ({
  type: ACCESS_TABLE,
  payload: key,
});

export const fetchMerchant = ({ merchantId }) => ({
  type: FETCH_MERCHANT,
  payload: { merchantId },
});

export const addToCart = item => ({
  type: ADD_TO_CART,
  payload: { item },
});

export const updateCart = item => ({
  type: UPDATE_CART,
  payload: item,
});

export const fetchProducts = ({ merchantId }) => ({
  type: FETCH_PRODUCTS,
  payload: { merchantId },
});

export const fetchMore = ({ merchantId }) => ({
  type: FETCH_MORE,
  payload: { merchantId },
});

export const startCheckout = ({
  cart,
  pickupDate,
  customer,
  merchantId,
  onError,
}) => ({
  type: START_CHECKOUT,
  payload: {
    cart,
    pickupDate,
    customer,
    merchantId,
    onError,
  },
});

export const getOrderDetails = ({ sessionId, merchantId }) => ({
  type: GET_ORDER_DETAILS,
  payload: {
    sessionId,
    merchantId,
  },
});

export const addItem = ({ item, merchantId }) => ({
  type: ADD_ITEM,
  payload: { item, merchantId },
});

export const deleteItem = ({ product_id, merchantId }) => ({
  type: DELETE_ITEM,
  payload: { product_id, merchantId },
});

export const updateItem = ({ item, merchantId }) => ({
  type: UPDATE_ITEM,
  payload: { item, merchantId },
});

export const addCategory = ({ category, merchantId }) => ({
  type: ADD_CATEGORY,
  payload: { category, merchantId },
});

export const deleteCategory = ({ category, merchantId }) => ({
  type: DELETE_CATEGORY,
  payload: { category, merchantId },
});

export const updateCategory = ({
  currentCategory,
  newCategory,
  merchantId,
}) => ({
  type: UPDATE_CATEGORY,
  payload: { currentCategory, newCategory, merchantId },
});

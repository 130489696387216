export const ADD_TO_CART = "ADD_TO_CART";
export const UPDATE_CART = "UPDATE_CART";
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCTS_STARTED = "FETCH_PRODUCTS_STARTED";
export const FETCH_PRODUCTS_FAILED = "FETCH_PRODUCTS_FAILED";
export const FETCH_PRODUCTS_SUCCEEDED = "FETCH_PRODUCTS_SUCCEEDED";
export const START_CHECKOUT = "START_CHECKOUT";
export const CHECKOUT_SUCCEEDED = "CHECKOUT_SUCCEEDED";
export const CHECKOUT_FAILED = "CHECKOUT_FAILED";
export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";
export const ORDER_COMPLETED = "ORDER_COMPLETED";
export const ORDER_FAILED = "ORDER_FAILED";
export const ADD_ITEM = "ADD_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const FETCH_MERCHANT = "FETCH_MERCHANT";
export const FETCH_MERCHANT_SUCCEEDED = "FETCH_MERCHANT_SUCCEEDED";
export const FETCH_MERCHANT_FAILED = "FETCH_MERCHANT_FAILED";
export const FETCH_MORE = "FETCH_MORE";
export const FETCH_MORE_STARTED = "FETCH_MORE_STARTED";
export const FETCH_MORE_SUCCEEDED = "FETCH_MORE_SUCCEEDED";
export const FETCH_MORE_FAILED = "FETCH_MORE_FAILED";
export const ACCESS_TABLE = "ACCESS_TABLE";
export const ACCESS_TABLE_SUCCESS = "ACCESS_TABLE_SUCCESS";
export const ACCESS_TABLE_FAILED = "ACCESS_TABLE_FAILED";

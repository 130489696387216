import axios from "axios";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import EasternMarketMap from "assets/eastern-market-map.png";
import { Button, RobotoText } from "./common";
import { CHECKOUT_SUCCEEDED } from "redux/actionTypes";

const useQuery = () => new URLSearchParams(useLocation().search);

const ConfirmationPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState({});
  const query = useQuery();
  const sessionId = query.get("session_id");

  const getOrderDetails = async () => {
    const merchantId = process.env.REACT_APP_MERCHANT_ID;
    setLoading(true);
    try {
      const result = await axios
        .get(
          `${process.env.REACT_APP_API_URL}/merchant/${merchantId}/session/${sessionId}/orders`,
        )
        .then(res => res.data);
      setOrder({ ...result.order, orderExists: true });
    } catch (e) {
      setOrder({ orderExists: false });
    } finally {
      setLoading(false);
      dispatch({ type: CHECKOUT_SUCCEEDED });
    }
  };

  const notifyOwnerOnCustomerArrival = async () => {
    const merchantId = process.env.REACT_APP_MERCHANT_ID;
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/merchant/${merchantId}/orders/${order.order_id}/notifications/pickup`,
      );
      getOrderDetails();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  if (loading) {
    return "Loading";
  }

  if (!order.orderExists) {
    return "Order doesn't exist";
  }

  return (
    <div>
      {order.status !== "completed" &&
        order.is_here_for_pickup &&
        "Thanks for letting us know you're here! Your order will be out shortly."}
      <Header>{`ORDER ID: ${order.order_id}`}</Header>
      <OrderDetailsContainer>
        <div>{`Email: ${order.email}`}</div>
      </OrderDetailsContainer>
      <DescriptionContainer as="p">
        Thank you for shopping at Capitol Hill Produce.
        <br />
        {!order.is_here_for_pickup && (
          <>
            <p>
              {"Let us know when you're here so we can bring the order to you."}
            </p>
            <p>Location A - curbside</p>
            <p>Location B - in-store</p>
          </>
        )}
      </DescriptionContainer>
      {!order.is_here_for_pickup && order.status !== "completed" && (
        <ArrivedButton onClick={notifyOwnerOnCustomerArrival}>
          {"I'm here"}
        </ArrivedButton>
      )}
      <img
        alt={"Eastern Market Map"}
        src={EasternMarketMap}
        height={400}
        width={400}
        style={{ paddingTop: "30px" }}
      />
    </div>
  );
};

const ArrivedButton = styled(Button)({
  paddingRight: 40,
  paddingLeft: 40,
});

const OrderDetailsContainer = styled(RobotoText)({
  fontSize: 20,
  paddingTop: 10,
});

const Header = styled(RobotoText)({
  fontWeight: 500,
  fontSize: 30,
  paddingTop: 20,
});

const DescriptionContainer = styled(RobotoText)({
  fontWeight: "500",
  fontSize: 16,
});

export default ConfirmationPage;

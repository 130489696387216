import { RobotoText } from "components/common";
import styled from "styled-components";

const isValidPhoneNumber = str =>
  (str.match(/\d+/) || !str) &&
  ((str[0] === "1" && str.length <= 11) ||
    (str[0] !== "1" && str.length <= 10));

const CustomerInfoForm = ({ form, setForm }) => {
  return (
    <CustomerInfoFormContainer>
      <Label htmlFor="customer-name">Name</Label>
      <Input
        id="customer-name"
        type="text"
        required
        onChange={evt => {
          setForm({ ...form, name: evt.target.value });
        }}
        value={form.name}
      />
      <Label htmlFor="customer-phone">Phone Number</Label>
      <Input
        id="customer-phone"
        type="tel"
        placeholder="8008008000"
        onChange={evt => {
          let number = evt.target.value;
          if (isValidPhoneNumber(number)) {
            setForm({ ...form, phoneNumber: number });
          }
        }}
        pattern="[1]?[0-9]{3}[0-9]{3}[0-9]{4}"
        value={form.phoneNumber}
      />
      <Label htmlFor="customer-email">Email</Label>
      <Input
        id="customer-email"
        type="email"
        required
        onChange={evt => {
          setForm({ ...form, email: evt.target.value });
        }}
        value={form.email}
      />
    </CustomerInfoFormContainer>
  );
};

const CustomerInfoFormContainer = styled(RobotoText)({
  display: "flex",
  flexDirection: "column",
});

const Label = styled.label({
  paddingBottom: 5,
});

const Input = styled.input({
  marginBottom: 20,
});

export default CustomerInfoForm;

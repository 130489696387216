import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/actions";
import styled from "styled-components";
import { Button } from "components/common";
import AddCategoryRow from "./AddCategoryRow";
import UpArrow from "assets/up-arrow.svg";
import DownArrow from "assets/down-arrow.svg";

const CategoryTable = () => {
  const dispatch = useDispatch();
  const [isAdding, setIsAdding] = useState(false);
  const [editingRowId, setEditingRowId] = useState("");
  const { categories } = useSelector(state => state.products);
  const [categoryName, setCategoryName] = useState("");

  const [searchItem, setSearchItem] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  const resetRow = () => {
    setCategoryName("");
  };

  const addCategoryRow = () => {
    dispatch(
      actions.addCategory({
        category: categoryName,
        merchantId: process.env.REACT_APP_MERCHANT_ID,
      }),
    );
    setIsAdding(false);
    resetRow();
  };

  const addRow = () => {
    setIsAdding(true);
    resetRow();
  };

  const cancelRow = () => {
    setIsAdding(false);
    resetRow();
  };

  const sortCategories = () => {
    if (sortOrder == "") {
      categories.sort((a, b) => {
        return a.localeCompare(b);
      });
    } else {
      categories.reverse();
    }
  };

  const sortHandler = () => {
    sortCategories();
    setSortOrder(sortOrder === "descending" || "" ? "ascending" : "descending");
  };

  return (
    <TableBox>
      {!isAdding && (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{ display: "flex", justifyContent: "flex-start", flex: 1 }}
          >
            <input
              placeholder="Search"
              type="text"
              value={searchItem}
              onChange={event => {
                setSearchItem(event.target.value.toLowerCase());
              }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", flex: 1 }}>
            <ConfirmButton onClick={addRow} style={{ height: 40, width: 150 }}>
              Add Category
            </ConfirmButton>
          </div>
        </div>
      )}
      <div
        style={{
          height: 500,
          overflow: "scroll",
        }}
      >
        <table id="customers">
          <thead>
            <tr>
              <TableHeader onClick={() => sortHandler()}>
                Category
                {sortOrder !== "" && (
                  <img
                    style={{ height: 15, width: 20 }}
                    src={sortOrder === "descending" ? DownArrow : UpArrow}
                  />
                )}
              </TableHeader>
              <TableHeader style={{ minWidth: 167 }}>Actions</TableHeader>
            </tr>
          </thead>
          <tbody>
            {isAdding && (
              <AddCategoryRow
                {...{
                  onAdd: addCategoryRow,
                  onCancel: cancelRow,
                  categoryName,
                  setCategoryName,
                }}
              />
            )}
            {categories.map(
              category =>
                category.toLowerCase().includes(searchItem) && (
                  <EditRow
                    isAdding={isAdding}
                    editingRowId={editingRowId}
                    setEditingRowId={setEditingRowId}
                    key={category}
                    category={category}
                  />
                ),
            )}
          </tbody>
        </table>
      </div>
    </TableBox>
  );
};

const EditRow = ({ isAdding, editingRowId, setEditingRowId, category }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(category);

  const deleteCategoryRow = () => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete this category, ${category}?`,
    );
    if (confirmDelete) {
      dispatch(
        actions.deleteCategory({
          category,
          merchantId: process.env.REACT_APP_MERCHANT_ID,
        }),
      );
    }
  };
  const updateCategoryRow = () => {
    dispatch(
      actions.updateCategory({
        currentCategory: category,
        newCategory: value,
        merchantId: process.env.REACT_APP_MERCHANT_ID,
      }),
    );
    setEditingRowId("");
  };

  const resetCategoryData = () => {
    setValue(category);
  };

  return editingRowId !== category ? (
    <tr
      style={
        isAdding || editingRowId !== ""
          ? {
              opacity: 0.1,
              pointerEvents: "none",
              userSelect: "none",
            }
          : {}
      }
    >
      <td>{category}</td>
      <td>
        <ConfirmButton onClick={() => setEditingRowId(category)}>
          Edit
        </ConfirmButton>
        <CancelButton onClick={deleteCategoryRow}>Delete</CancelButton>
      </td>
    </tr>
  ) : (
    <tr>
      <td>
        <input
          type="text"
          value={value}
          onChange={event => setValue(event.target.value)}
        />
      </td>
      <td>
        <ConfirmButton onClick={updateCategoryRow}>Save</ConfirmButton>
        <CancelButton
          onClick={() => {
            setEditingRowId("");
            resetCategoryData();
          }}
        >
          Cancel
        </CancelButton>
      </td>
    </tr>
  );
};

const TableBox = styled.section({
  display: "flex",
  flexDirection: "column",
  flex: 6,
});

const ConfirmButton = styled(Button)({
  display: "initial",
  padding: 5,
  width: 75,
});

const CancelButton = styled(Button)({
  display: "initial",
  backgroundColor: "#b82424",
  padding: 5,
  width: 75,
  ":hover": { backgroundColor: "#881b1b" },
});

const TableHeader = styled.th({
  userSelect: "none",
  cursor: "pointer",
  position: "sticky",
  top: -1,
  borderTop: "black 1px solid",
});
export default CategoryTable;

import { put, takeEvery, call } from "redux-saga/effects";
import axios from "axios";
import { getStripeInstance } from "../../utils/stripe";
import { convertToCents } from "../../utils/currency";
import {
  CHECKOUT_SUCCEEDED,
  CHECKOUT_FAILED,
  START_CHECKOUT,
} from "../actionTypes";

function startCheckoutSession({ cart, merchantId, pickupDate, customer }) {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/checkout/session`, {
      cart: cart.map(item => ({
        product_id: item.product_id,
        name: item.name,
        price: convertToCents(item.price),
        quantity: item.quantity || 1,
        image_url: item.image_url,
        unit: item.unit,
      })),
      merchantId,
      pickupDate,
      customer,
    })
    .then(response => response.data);
}

export function* handleStartCheckout(action) {
  const { cart, onError } = action.payload;
  try {
    const session = yield call(startCheckoutSession, action.payload);
    const stripe = yield getStripeInstance(session.stripeAccountId);
    yield stripe.redirectToCheckout({
      sessionId: session.id,
    });
    yield put({ type: CHECKOUT_SUCCEEDED, payload: cart });
  } catch (e) {
    yield put({ type: CHECKOUT_FAILED, payload: cart });
  } finally {
    if (onError) {
      onError();
    }
  }
}

export function* watchStartCheckout() {
  yield takeEvery(START_CHECKOUT, handleStartCheckout);
}

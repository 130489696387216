import styled from "styled-components";
import { Button, RobotoText } from "components/common";
import CustomerInfoForm from "./CustomerInfoForm";
import OrderDetails from "./OrderDetails";
import PickupTimeDropdown from "./PickupTimeDropdown";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startCheckout } from "redux/actions";
import { getPickupDates } from "utils/merchant";

const Checkout = () => {
  const dispatch = useDispatch();
  const { merchant_hours } = useSelector(state => state.merchant);
  const { items } = useSelector(state => state.cart);
  const pickupDates = getPickupDates(merchant_hours);
  const [loading, setLoading] = useState(false);
  const [pickupDate, setPickupDate] = useState(pickupDates[0]);
  const [pickupTime, setPickupTime] = useState(pickupDates[0].pickup_open_time);
  const [customerForm, setForm] = useState({
    name: "",
    phoneNumber: "",
    email: "",
  });

  return (
    <CheckoutContainer
      onSubmit={event => {
        event.preventDefault();
        dispatch(
          startCheckout({
            cart: items,
            pickupDate: `${pickupDate.value} ${pickupTime}`,
            customer: customerForm,
            // TODO - merchantId will be dynamic later.
            merchantId: process.env.REACT_APP_MERCHANT_ID,
            onError: () => setLoading(false),
          }),
        );
      }}
    >
      <div className="bg-indigo-100 w-1/3 p-10">
        <SectionContainer>
          <SectionHeader>PICKUP TIME</SectionHeader>
          <SectionContent>
            <PickupTimeDropdown
              {...{
                pickupDates,
                pickupDate,
                setPickupDate,
                pickupTime,
                setPickupTime,
              }}
            />
          </SectionContent>
        </SectionContainer>
        <Divider />
        <SectionContainer>
          <SectionHeader>CUSTOMER INFO</SectionHeader>
          <SectionContent>
            <CustomerInfoForm {...{ form: customerForm, setForm }} />
          </SectionContent>
        </SectionContainer>
        <Divider />
        <SectionContainer>
          <SectionHeader>ORDER DETAILS</SectionHeader>
          <SectionContent>
            <OrderDetails />
          </SectionContent>
        </SectionContainer>
        <SectionContainer style={{ paddingTop: 20 }}>
          <SectionContent>
            <button
              className="bg-primary font-bold text-white w-full py-3 rounded-lg"
              type="submit"
              disabled={loading}
            >
              {loading ? "LOADING..." : "PAY"}
            </button>
          </SectionContent>
        </SectionContainer>
      </div>
    </CheckoutContainer>
  );
};

const CheckoutContainer = styled.form({
  display: "flex",
  justifyContent: "center",
  marginTop: 20,
});

const Divider = styled.div({
  borderBottom: "0.5px solid #C4C4C4",
  width: "101%",
  marginBottom: 15,
  marginTop: 15,
});

const SectionContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const SectionHeader = styled.div({
  fontSize: 25,
  fontWeight: 700,
  paddingBottom: 20,
});

const SectionContent = styled.div({
  alignSelf: "center",
  width: 300,
});

export default Checkout;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import EasternMarket from "assets/eastern-market.jpg";
import { forceCheck } from "react-lazyload";
import Product from "components/Store/Product";

const Home = ({ onProductClick }) => {
  const { loading, products, categories } = useSelector(
    state => state.products,
  );
  const [categoryFilter, setCategoryFilter] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [showFilters, setShowFilters] = useState(false);

  const filteredCategoryProducts = categoryFilter.length
    ? products.filter(product => {
        return categoryFilter === product?.category;
      })
    : products;

  const filteredProducts = filteredCategoryProducts.filter(product => {
    return product.name.toLowerCase().includes(searchFilter.toLowerCase());
  });

  useEffect(() => {
    forceCheck();
  }, [searchFilter]);

  if (loading) {
    return "Loading...";
  }

  return (
    <>
      <header>
        <div className="container mx-auto px-6 pb-3">
          {/* <nav className="sm:flex sm:justify-center sm:items-center mt-4">
            <div className="flex flex-col sm:flex-row">
              <a
                className="mt-3 text-gray-600 hover:underline sm:mx-3 sm:mt-0"
                href="#"
              >
                Home
              </a>
              <a
                className="mt-3 text-gray-600 hover:underline sm:mx-3 sm:mt-0"
                href="#"
              >
                Shop
              </a>
              <a
                className="mt-3 text-gray-600 hover:underline sm:mx-3 sm:mt-0"
                href="#"
              >
                Categories
              </a>
              <a
                className="mt-3 text-gray-600 hover:underline sm:mx-3 sm:mt-0"
                href="#"
              >
                Contact
              </a>
              <a
                className="mt-3 text-gray-600 hover:underline sm:mx-3 sm:mt-0"
                href="#"
              >
                About
              </a>
            </div>
          </nav> */}
          <div className="relative max-w-lg mx-auto">
            <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
              <svg
                className="h-5 w-5 text-gray-500"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <input
              className="w-full border rounded-md pl-10 pr-4 py-2 focus:border-blue-500 focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="Search"
              value={searchFilter}
              onChange={event => {
                setSearchFilter(event.target.value);
              }}
            />
          </div>
        </div>
      </header>
      <div className="flex flex-col lg:flex-row lg:items-start">
        <div className="hidden bg-white shadow container lg:w-64 mx-auto lg:ml-6 flex-col p-6 rounded-xl lg:flex">
          <h3 className="text-gray-700 text-2xl font-normal self-center">
            Categories
          </h3>
          {
            <button
              className="mt-6 h-10 px-5 text-primary transition-colors duration-300 border border-primary rounded-lg hover:bg-primary hover:text-white focus:outline-none"
              onClick={() => {
                setCategoryFilter("");
              }}
            >
              Clear Filter
            </button>
          }
          <div className="flex flex-col mt-3">
            {categories.map(category =>
              category === categoryFilter ? (
                <div
                  className="my-1 font-bold cursor-pointer"
                  key={category}
                  onClick={() => {
                    setCategoryFilter(category);
                  }}
                >
                  {category}
                </div>
              ) : (
                <div
                  className="my-1 font-light cursor-pointer"
                  key={category}
                  onClick={() => {
                    setCategoryFilter(category);
                  }}
                >
                  {category}
                </div>
              ),
            )}
          </div>
        </div>

        {!showFilters ? (
          <button
            onClick={() => setShowFilters(true)}
            className="lg:hidden m-6 h-10 px-5 text-primary transition-colors duration-150 border border-primary rounded-lg hover:bg-primary hover:text-white focus:outline-none"
          >
            Show Filters
          </button>
        ) : (
          <div className="bg-white shadow container mx-auto m-6 flex flex-col p-6 rounded-xl lg:hidden">
            <button
              onClick={() => setShowFilters(false)}
              className="absolute focus:outline-none"
            >
              X
            </button>
            <h3 className="text-gray-700 text-2xl font-normal self-center">
              Categories
            </h3>
            {
              <button
                className="mt-6 h-10 px-5 text-primary transition-colors duration-150 border border-primary rounded-lg focus:shadow-outline hover:bg-primary hover:text-white focus:outline-none"
                onClick={() => {
                  setCategoryFilter("");
                }}
              >
                Clear Filter
              </button>
            }
            <div className="flex flex-col mt-3">
              {categories.map(category =>
                category === categoryFilter ? (
                  <div
                    className="my-1 font-bold cursor-pointer"
                    key={category}
                    onClick={() => {
                      setCategoryFilter(category);
                    }}
                  >
                    {category}
                  </div>
                ) : (
                  <div
                    className="my-1 font-light cursor-pointer"
                    key={category}
                    onClick={() => {
                      setCategoryFilter(category);
                    }}
                  >
                    {category}
                  </div>
                ),
              )}
            </div>
          </div>
        )}

        <main className="flex-1">
          <div className="mx-auto px-6">
            <h3 className="text-gray-700 text-2xl font-medium">Products</h3>
            <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6">
              {filteredProducts.map(product => {
                return <Product key={product.product_id} product={product} />;
              })}
            </div>
            {/* <div className="flex justify-center">
            <div className="flex rounded-md mt-8">
              <a
                href="#"
                className="py-2 px-4 leading-tight bg-white border border-gray-200 text-blue-700 border-r-0 ml-0 rounded-l hover:bg-blue-500 hover:text-white"
              >
                <span>Previous</span>
              </a>
              <a
                href="#"
                className="py-2 px-4 leading-tight bg-white border border-gray-200 text-blue-700 border-r-0 hover:bg-blue-500 hover:text-white"
              >
                <span>1</span>
              </a>
              <a
                href="#"
                className="py-2 px-4 leading-tight bg-white border border-gray-200 text-blue-700 border-r-0 hover:bg-blue-500 hover:text-white"
              >
                <span>2</span>
              </a>
              <a
                href="#"
                className="py-2 px-4 leading-tight bg-white border border-gray-200 text-blue-700 border-r-0 hover:bg-blue-500 hover:text-white"
              >
                <span>3</span>
              </a>
              <a
                href="#"
                className="py-2 px-4 leading-tight bg-white border border-gray-200 text-blue-700 rounded-r hover:bg-blue-500 hover:text-white"
              >
                <span>Next</span>
              </a>
            </div>
          </div> */}
          </div>
        </main>
      </div>
    </>
  );
};

export default Home;

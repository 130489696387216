import React, { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { Button } from "components/common";
import styled from "styled-components";

const AddItemRow = ({
  onAdd,
  onCancel,
  categories,
  handleFileUpload,
  rowData,
  setRowData,
}) => {
  const [containsError, setContainsError] = useState(false);

  const addHandler = () => {
    if (
      rowData.name === "" ||
      rowData.units === "" ||
      rowData.image === undefined
    ) {
      setContainsError(true);
    } else {
      onAdd();
    }
  };

  return (
    <tr>
      {containsError && rowData.name === "" && (
        <ErrorMessage>*Name Required</ErrorMessage>
      )}
      <td className="pr-5 mb-3 pt-0 w-44">
        <input
          className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:ring w-full"
          type="text"
          value={rowData?.name}
          onChange={event =>
            setRowData({ ...rowData, name: event.target.value })
          }
        />
      </td>
      <td className="pr-5">
        <input
          className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:ring w-full"
          type="text"
          value={rowData?.description}
          onChange={event =>
            setRowData({
              ...rowData,
              description: event.target.value,
            })
          }
        />
      </td>
      <td className="pr-5 w-24">
        <CurrencyInput
          className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:ring w-full"
          name="price"
          prefix="$"
          value={rowData?.price}
          decimalsLimit={2}
          allowNegativeValue={false}
          onValueChange={value => {
            if (value) {
              setRowData({ ...rowData, price: value });
            } else {
              setRowData({ ...rowData, price: "0" });
            }
          }}
        />
        {rowData.price === "0" && (
          <ErrorMessage style={{ color: "skyblue" }}>Price is $0</ErrorMessage>
        )}
      </td>
      <td className="pr-5 w-24">
        <input
          className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:ring w-full"
          type="text"
          value={rowData?.unit}
          onChange={event =>
            setRowData({ ...rowData, unit: event.target.value })
          }
        />
        {containsError && rowData.unit === "" && (
          <ErrorMessage>*Units Required</ErrorMessage>
        )}
      </td>
      <td className="pr-5">
        <select
          className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:ring w-full"
          onChange={event =>
            setRowData({
              ...rowData,
              category: event.target.value,
            })
          }
        >
          {["None", ...categories].map(category => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </td>
      <td className="flex flex-col">
        <img src={rowData?.image?.url} height={50} width={50} />
        <input
          className="w-60"
          type="file"
          onChange={handleFileUpload}
          accept="image/*"
        />
        {containsError && rowData.image === undefined && (
          <ErrorMessage>*Image Required</ErrorMessage>
        )}
      </td>
      <td>
        <input
          type="checkbox"
          onChange={event => {
            const checked = event.target.checked;
            setRowData({ ...rowData, is_seasonal: checked });
          }}
        />
      </td>
      <td>
        <button
          className="bg-indigo-100 text-primary py-2 px-6 rounded-lg mr-5"
          onClick={() => {
            addHandler();
          }}
        >
          Save
        </button>
        <button
          className="bg-red-100 text-primary py-2 px-6 rounded-lg"
          onClick={onCancel}
        >
          Cancel
        </button>
      </td>
    </tr>
  );
};

const ConfirmButton = styled(Button)({
  display: "initial",
  padding: 5,
  width: 75,
});

const CancelButton = styled(Button)({
  display: "initial",
  backgroundColor: "#b82424",
  padding: 5,
  width: 75,
  ":hover": { backgroundColor: "#881b1b" },
});

const ErrorMessage = styled.div({
  color: "red",
});

export default AddItemRow;

import {
  FETCH_PRODUCTS_FAILED,
  FETCH_PRODUCTS_STARTED,
  FETCH_PRODUCTS_SUCCEEDED,
  FETCH_MORE_STARTED,
  FETCH_MORE_SUCCEEDED,
  FETCH_MORE_FAILED,
} from "../actionTypes";

const initialState = {
  loading: false,
  products: [],
  categories: [],
  error: false,
  limit: 10,
  offset: "",
};

export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCTS_STARTED: {
      return {
        ...state,
        offset: "",
        loading: true,
        error: false,
      };
    }
    case FETCH_PRODUCTS_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        error: false,
        products: action.payload.products,
        categories: action.payload.categories,
      };
    }
    case FETCH_PRODUCTS_FAILED: {
      return {
        loading: false,
        categories: [],
        products: [],
        error: true,
      };
    }
    case FETCH_MORE_STARTED: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case FETCH_MORE_SUCCEEDED: {
      // console.log(state.products, action.payload.products);
      // console.log(state.offset + 10);
      return {
        ...state,
        loading: false,
        error: false,
        offset: "", // TODO
        products: [...state.products, ...action.payload.products],
      };
    }
    case FETCH_MORE_FAILED: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    default: {
      return state;
    }
  }
}

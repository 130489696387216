import React from "react";
import LazyLoad from "react-lazyload";
import { currency } from "utils/currency";

import AddCartButton from "components/Store/AddCartButton";

const Product = ({ product }) => {
  const unit = product.unit ? ` / ${product.unit}` : "";

  return (
    <div className="w-full max-w-sm mx-auto rounded-md shadow-md">
      <LazyLoad>
        <div
          className="relative h-56 w-full bg-cover"
          style={{
            backgroundImage: `url(${product.image_url})`,
          }}
        >
          {/* <AddCartButton product={product} /> */}
        </div>
      </LazyLoad>
      <div className="px-5 py-3">
        <h3 className="text-gray-700">{product.name}</h3>
        <span className="text-gray-500 mt-2">{`${currency(
          product.price,
        )}${unit}`}</span>
      </div>
    </div>
  );
};
export default Product;

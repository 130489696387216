import { RobotoText } from "components/common";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { currency } from "utils/currency";

const OrderDetails = () => {
  const { subtotal } = useSelector(state => state.cart);
  const { taxRate } = useSelector(state => state.merchant);
  const tax = (taxRate.percentage / 100) * subtotal;

  return (
    <OrderDetailsContainer>
      <Line>
        <Item>Subtotal</Item>
        <Item>{currency(subtotal)}</Item>
      </Line>
      <Line>
        <Item>Tax</Item>
        <Item>{currency(tax)}</Item>
      </Line>
      <Line>
        <Item>Total</Item>
        <Item>{currency(subtotal + tax)}</Item>
      </Line>
    </OrderDetailsContainer>
  );
};

const OrderDetailsContainer = styled(RobotoText)({
  display: "flex",
  flexDirection: "column",
  letterSpacing: 0,
});

const Line = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingBottom: 10,
});

const Item = styled.div({
  fontSize: 16,
});

export default OrderDetails;

import React from "react";
import styled from "styled-components";
import EasternMarketMap from "assets/eastern-market-map.png";
import { CourierText } from "./common";

const Location = () => {
  return (
    <LocationContainer>
      <InfoBox>
        <div style={{ marginBottom: "40px" }}>
          <Heading as="p">Where to find us</Heading>
          <p>You can visit our store inside Eastern Market at location B.</p>
          <div>225 7th St SE Washington, DC</div>
        </div>
        <div style={{ marginBottom: "40px" }}>
          <Heading as="p">Pick-up Orders</Heading>
          <p>Meet us at one of these locations for pick-up orders.</p>
          <p>Location A - curbside</p>
          <p>Location B - in-store</p>
        </div>
        <div style={{ marginBottom: "40px" }}>
          <Heading as="p">Hours of Operation</Heading>
          <div>Mon: closed</div>
          <div>Tues-Sun: 9:00 AM to 5:00 PM</div>
        </div>
        <div>
          <Heading as="p">Call us!</Heading>
          <p>(202)-544-4435</p>
        </div>
      </InfoBox>

      <Map alt="map" src={EasternMarketMap} />
    </LocationContainer>
  );
};

const LocationContainer = styled.div({
  display: "flex",
  justifyContent: "space-between",
  "@media (max-width: 1100px)": {
    flexDirection: "column",
  },
});

const InfoBox = styled(CourierText)({
  fontSize: 22,
  width: 400,
  "@media (max-width: 1100px)": {
    width: "100%",
  },
  "@media (max-width: 500px)": {
    width: 400,
  },
});

const Heading = styled.p({
  fontWeight: 700,
});

const Map = styled.img({
  position: "flex",
  alignItems: "center",
  height: "46%",
  width: "46%",
  paddingLeft: 50,
  "@media (max-width: 1100px)": {
    paddingLeft: 0,
    height: 400,
    width: 400,
  },
});

export default Location;

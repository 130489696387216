import { Button } from "components/common";
import styled from "styled-components";

const AddCategoryRow = ({ onAdd, onCancel, categoryName, setCategoryName }) => {
  return (
    <tr>
      <td>
        <input
          type="text"
          value={categoryName}
          onChange={event => setCategoryName(event.target.value)}
        />
      </td>
      <td>
        <ConfirmButton onClick={onAdd}>Save</ConfirmButton>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
      </td>
    </tr>
  );
};

const ConfirmButton = styled(Button)({
  display: "initial",
  padding: 5,
  width: 75,
});

const CancelButton = styled(Button)({
  display: "initial",
  backgroundColor: "#b82424",
  padding: 5,
  width: 75,
  ":hover": { backgroundColor: "#881b1b" },
});

export default AddCategoryRow;

import {
  FETCH_MERCHANT,
  FETCH_MERCHANT_SUCCEEDED,
  FETCH_MERCHANT_FAILED,
  ACCESS_TABLE_SUCCESS,
  ACCESS_TABLE_FAILED,
} from "../actionTypes";

const initialState = {
  loading: false,
  zip: "",
  street_address: "",
  stripe_tax_rate_id: "",
  city: "",
  stripe_account_id: "",
  merchant_hours: {},
  service_transaction_flat_fee: "",
  name: "",
  phone_number: "",
  state: "",
  email: "",
  taxRate: { percentage: 0 },
  table: false,
};

export default function merchantReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MERCHANT: {
      return {
        ...state,
        errorMessage: "",
        loading: true,
      };
    }
    case FETCH_MERCHANT_SUCCEEDED: {
      return {
        ...state,
        ...action.payload,
        loaded: false,
      };
    }
    case FETCH_MERCHANT_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case ACCESS_TABLE_SUCCESS: {
      return {
        ...state,
        table: true,
      };
    }
    case ACCESS_TABLE_FAILED: {
      return {
        ...state,
        table: false,
        errorMessage: "Incorrect login code.",
      };
    }
    default: {
      return state;
    }
  }
}

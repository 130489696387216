import styled from "styled-components";
import { getPickupTimes } from "utils/merchant";

const PickupTimeDropdown = ({
  pickupDates,
  pickupDate,
  setPickupDate,
  pickupTime,
  setPickupTime,
}) => {
  const pickupTimes = getPickupTimes(pickupDate);

  return (
    <PickupTimeDropdownContainer>
      <Dropdown
        onChange={event => {
          setPickupDate(
            pickupDates.find(date => date.value === event.target.value),
          );
        }}
        value={pickupDate.value}
      >
        {pickupDates.map(item => (
          <option key={item.value} value={item.value}>{`${item.label}`}</option>
        ))}
      </Dropdown>
      <Dropdown
        onChange={event => {
          setPickupTime(event.target.value);
        }}
        value={pickupTime.value}
      >
        {pickupTimes.map(item => (
          <option key={item.value} value={item.value}>{`${item.label}`}</option>
        ))}
      </Dropdown>
    </PickupTimeDropdownContainer>
  );
};

const PickupTimeDropdownContainer = styled.div({
  display: "flex",
  width: "100%",
});

const Dropdown = styled.select({
  backgroundColor: "white",
  color: "black",
  fontSize: 16,
  fontWeight: "bold",
  paddingLeft: 10,
  height: 30,
  width: "100%",
});

export default PickupTimeDropdown;
